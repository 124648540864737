<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import { ref, onMounted, watchEffect } from 'vue';
import {useGuidesStore} from '~/stores/guides.store'

const guideStore = useGuidesStore()
const guidesCountries = ref([]);
const route = useRoute();
const selectedCountrySlug = route.params.location;

guidesCountries.value = await guideStore.fetchAreaGuideCountries();
</script>
<template>
	<div class="" v-for="country in guidesCountries.data">
    <NuxtLink :to="localePath({ name: 'guides.show', params: { location: country.country_slug } })" 
    class="hover:text-indigo-900 lg:px-3 sm:px-1.5 md:px-2 py-2"
    :class="{ [selectedCountrySlug == country.country_slug ? 'text-indigo-900' : 'text-neutral-400']: true }"
    aria-current="page">{{ country.country_name }}</NuxtLink>
  </div>
</template>
